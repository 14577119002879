<template>
  <div
    class="sidebar"
    :class="{open}">
    <div class="sidebar-menu">
      <ul class="nav nav-pills flex-column">
        <SidebarItem
          icon="home"
          label="general.home"
          :to="{name: 'homepage'}"/>
        <template v-if="!isAuthenticated">
          <SidebarItem
            v-if="features.postalCodeCheck"
            icon="search"
            label="postal-code-check.title"
            :to="{name: 'postal-code-check'}"/>
          <SidebarItem
            icon="signature"
            label="apply.button._"
            :to="{name: 'apply'}"/>
        </template>
        <template v-if="isAuthenticated">
          <SidebarItem
            icon="building"
            label="company._"
            :to="{name: 'my-company'}"/>
          <SidebarItem
            v-if="isLoyaltyParticipant"
            icon="piggy-bank"
            label="loyalty._"
            :to="{name: 'loyalty'}"/>
          <SidebarItem
            v-if="isAdmin"
            icon="signature"
            label="company.title.agreements"
            :to="{name: 'my-company-agreements'}"/>
          <SidebarItem
            v-if="features.userManagement && isAdmin"
            icon="users"
            label="user-management._"
            :to="{name: 'user-management'}"/>
          <SidebarItem
            v-if="features.myLines"
            icon="network-wired"
            label="lines._"
            :to="{name: 'my-lines'}"/>
          <SidebarItem
            v-if="features.rackSpace"
            icon="server"
            label="racks._"
            :to="{name: 'my-racks'}"/>
          <SidebarItem
            v-if="features.dcConnections"
            icon="link"
            label="dc-connections._"
            :to="{name: 'my-dc-connections'}"/>
          <SidebarItem
            v-if="features.wdm"
            icon="route"
            label="wdm._"
            :to="{name: 'my-wdm'}"/>
          <SidebarItem
            v-if="features.ipManagement && isServiceDesk"
            icon="globe-europe"
            label="ip-management._"
            :to="{name: 'ip-management'}"/>
          <SidebarItem
            v-if="features['101010tv'] && isServiceDesk"
            icon="tv"
            label="101010tv.accounts.menu"
            :to="{name: '101010tv-accounts'}"/>
          <SidebarItem
            v-if="features.myInvoices && isFinance"
            icon="file-invoice"
            label="invoices._"
            :to="{name: 'my-invoices'}"/>
          <SidebarItem
            v-if="features.myOrders && canOrder"
            icon="shopping-cart"
            label="orders._"
            :to="{name: 'my-orders'}"/>
          <SidebarItem
            v-if="features.quotation && canOrder"
            icon="file-circle-question"
            label="quotations._"
            :to="{name: 'my-quotations'}"/>
          <SidebarItem
            v-if="features.postalCodeCheck"
            icon="search"
            label="postal-code-check.title"
            :to="{name: 'postal-code-check'}"/>
          <SidebarItem
            icon="sign-out-alt"
            label="auth.logout"
            :to="{name: 'logout'}"/>
        </template>
      </ul>
    </div>
  </div>

  <div
    class="sidebar-backdrop"
    :class="{show: open}"
    @click="open = false"/>
</template>

<script lang="ts">
import {AppRoles} from '@plugins/auth/AppRoles';
import {useAuthenticator} from '@plugins/auth/auth';
import {useEventbus} from '@plugins/eventbus/eventbus';
import {useFeatures} from '@plugins/features/features';
import {defineComponent, ref} from 'vue';
import SidebarItem from './SidebarItem.vue';

export default defineComponent({
  components: {SidebarItem},
  setup() {
    const open = ref(false);
    const authenticator = useAuthenticator();
    const eventBus = useEventbus();

    eventBus.on('toggle-sidebar', () => {
      open.value = !open.value;
    });
    eventBus.on('close-sidebar', () => {
      open.value = false;
    });

    return {
      open,
      features: useFeatures().features,
      isAuthenticated: authenticator.isAuthenticated,
      isLoyaltyParticipant: authenticator.isLoyaltyParticipant,
      isAdmin: authenticator.hasRoleRef(AppRoles.RESELLER_ADMIN),
      canOrder: authenticator.hasRoleRef(AppRoles.RESELLER_ORDER),
      isServiceDesk: authenticator.hasRoleRef(AppRoles.RESELLER_SERVICE_DESK),
      isFinance: authenticator.hasRoleRef(AppRoles.RESELLER_FINANCE),
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/assets/css/variables';
@import 'src/assets/css/mixins/position';

.sidebar {
  position: fixed;
  top: $navbar-height;
  bottom: 0;
  left: -$sidebar-width;
  width: $sidebar-width;
  padding: map-get($spacers, 1);
  border-right: $sidebar-border;
  background-color: $white;
  z-index: 500;

  @include transition(all $default-animation);
  transition-property: left, width;

  @include media-breakpoint-up(sm) {
    left: 0;
    width: $sidebar-collapsed-width;

    .menu-item {
      .label {
        opacity: 0;
        @include transition(opacity $default-animation);
      }
    }

    &:hover {
      width: $sidebar-width;

      .menu-item {
        .label {
          opacity: 1;
        }
      }
    }
  }

  &.open {
    left: 0;
    width: $sidebar-width;
  }

  .sidebar-menu {
    overflow: hidden auto;
  }
}

.sidebar-backdrop {
  @include absolute-full-cover(fixed);

  pointer-events: none;
  z-index: 400;
  opacity: 0;
  background-color: transparentize($black, 0.6);
  backdrop-filter: blur(2px);

  @include transition(opacity $default-animation);

  &.show {
    pointer-events: all;
    opacity: 1;
  }
}
</style>
